import { createStylesParam, createStylesParams, StyleParamType, wixColorParam } from '@wix/tpa-settings';
import { CustomCssVarsFn } from '@wix/yoshi-flow-editor';
import { Alignment } from '../../utils/alignment';

type PageCustomizationStylesParams = {
  pageHorizontalPadding: StyleParamType.Number;
  pageVerticalPadding: StyleParamType.Number;
  pageColumnSpacing: StyleParamType.Number;
  planFormRowSpacing: StyleParamType.Number;
  planFormColumnSpacing: StyleParamType.Number;
  planCardImageHeight: StyleParamType.Number;
  planCardAlignment: StyleParamType.Number;
  showPageTitle: StyleParamType.Boolean;
  showPlanCard: StyleParamType.Boolean;
  showPlanCardImage: StyleParamType.Boolean;
  showPlanCardName: StyleParamType.Boolean;
  showPlanCardDescription: StyleParamType.Boolean;
  showPlanCardPerks: StyleParamType.Boolean;
  pageBackgroundColor: StyleParamType.Color;
  planFormBackgroundColor: StyleParamType.Color;
  planFormBorderColor: StyleParamType.Color;
  planFormBorderRadius: StyleParamType.Number;
  planFormBorderWidth: StyleParamType.Number;
  planFormEnableShadow: StyleParamType.Boolean;
  planFormShadowAngle: StyleParamType.Number;
  planFormShadowDistance: StyleParamType.Number;
  planFormShadowBlur: StyleParamType.Number;
  planFormShadowColor: StyleParamType.Color;
  planCardBackgroundColor: StyleParamType.Color;
  planCardBorderColor: StyleParamType.Color;
  planCardBorderWidth: StyleParamType.Number;
  planCardBorderRadius: StyleParamType.Number;
  planCardEnableShadow: StyleParamType.Boolean;
  planCardShadowAngle: StyleParamType.Number;
  planCardShadowDistance: StyleParamType.Number;
  planCardShadowBlur: StyleParamType.Number;
  planCardShadowColor: StyleParamType.Color;
};

const componentStyles = createStylesParams<PageCustomizationStylesParams>({
  pageHorizontalPadding: createStylesParam('page-horizontal-padding', {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  }),
  pageVerticalPadding: createStylesParam('page-vertical-padding', {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  }),
  pageColumnSpacing: createStylesParam('page-column-spacing', {
    type: StyleParamType.Number,
    getDefaultValue: () => 40,
  }),
  planFormRowSpacing: createStylesParam('plan-form-row-spacing', {
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
  }),
  planFormColumnSpacing: createStylesParam('plan-form-column-spacing', {
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
  }),
  planCardImageHeight: createStylesParam('plan-card-image-height', {
    type: StyleParamType.Number,
    getDefaultValue: () => 160,
  }),
  planCardAlignment: createStylesParam('plan-card-alignment', {
    type: StyleParamType.Number,
    getDefaultValue: () => Alignment.CENTER,
  }),
  showPageTitle: createStylesParam('show-page-title', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  showPlanCard: createStylesParam('show-plan-card', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  showPlanCardImage: createStylesParam('show-plan-card-image', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  showPlanCardName: createStylesParam('show-plan-card-name', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  showPlanCardDescription: createStylesParam('show-plan-card-description', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  showPlanCardPerks: createStylesParam('show-plan-card-perks', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  }),
  pageBackgroundColor: createStylesParam('page-background-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-fill-background-primary', 0),
  }),
  planFormBackgroundColor: createStylesParam('plan-form-background-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-fill-background-primary', 0),
  }),
  planFormBorderColor: createStylesParam('plan-form-border-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-line', 0.2),
  }),
  planFormBorderRadius: createStylesParam('plan-form-border-radius', {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  }),
  planFormBorderWidth: createStylesParam('plan-form-border-width', {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  }),
  planFormEnableShadow: createStylesParam('plan-form-shadow', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  }),
  planFormShadowAngle: createStylesParam('plan-form-shadow-angle', {
    type: StyleParamType.Number,
    getDefaultValue: () => 315,
  }),
  planFormShadowDistance: createStylesParam('plan-form-shadow-distance', {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  }),
  planFormShadowBlur: createStylesParam('plan-form-shadow-blur', {
    type: StyleParamType.Number,
    getDefaultValue: () => 25,
  }),
  planFormShadowColor: createStylesParam('plan-form-shadow-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-line', 0.2),
  }),
  planCardBackgroundColor: createStylesParam('plan-card-background-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-fill-background-primary', 0),
  }),
  planCardBorderColor: createStylesParam('plan-card-border-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-line', 0.2),
  }),
  planCardBorderWidth: createStylesParam('plan-card-border-width', {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  }),
  planCardBorderRadius: createStylesParam('plan-card-border-radius', {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  }),
  planCardEnableShadow: createStylesParam('plan-card-apply-shadow', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  }),
  planCardShadowAngle: createStylesParam('plan-card-shadow-angle', {
    type: StyleParamType.Number,
    getDefaultValue: () => 315,
  }),
  planCardShadowDistance: createStylesParam('plan-card-shadow-distance', {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  }),
  planCardShadowBlur: createStylesParam('plan-card-shadow-blur', {
    type: StyleParamType.Number,
    getDefaultValue: () => 25,
  }),
  planCardShadowColor: createStylesParam('plan-card-shadow-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-line', 0.2),
  }),
});

export const customCssVars: CustomCssVarsFn = ({ styleParams }) => {
  const planCardShadowAngle = styleParams.numbers[componentStyles.planCardShadowAngle.key!] ?? 0;
  const planCardShadowDistance = styleParams.numbers[componentStyles.planCardShadowDistance.key!] ?? 0;

  const planFormShadowAngle = styleParams.numbers[componentStyles.planFormShadowAngle.key!] ?? 0;
  const planFormShadowDistance = styleParams.numbers[componentStyles.planFormShadowDistance.key!] ?? 0;

  const planCardAlignment = styleParams.numbers[componentStyles.planCardAlignment.key!] ?? Alignment.CENTER;
  return {
    'plan-card-text-align': alignmentToTextAlignValue(planCardAlignment),
    'plan-card-align-items': alignmentToAlignItemsValue(planCardAlignment),

    'plan-card-shadow-offset-x': -Math.round(Math.sin((planCardShadowAngle * Math.PI) / 180) * planCardShadowDistance),
    'plan-card-shadow-offset-y': Math.round(Math.cos((planCardShadowAngle * Math.PI) / 180) * planCardShadowDistance),

    'plan-form-shadow-offset-x': -Math.round(Math.sin((planFormShadowAngle * Math.PI) / 180) * planFormShadowDistance),
    'plan-form-shadow-offset-y': Math.round(Math.cos((planFormShadowAngle * Math.PI) / 180) * planFormShadowDistance),
  };
};

export default componentStyles;

function alignmentToTextAlignValue(alignment: Alignment): 'left' | 'center' | 'right' {
  switch (alignment) {
    case Alignment.LEFT:
      return 'left';
    case Alignment.CENTER:
      return 'center';
    case Alignment.RIGHT:
      return 'right';
  }
}

function alignmentToAlignItemsValue(alignment: Alignment): 'start' | 'center' | 'end' {
  switch (alignment) {
    case Alignment.LEFT:
      return 'start';
    case Alignment.CENTER:
      return 'center';
    case Alignment.RIGHT:
      return 'end';
  }
}
